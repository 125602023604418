@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.footer {
  height: auto;
  background: $primary-dark; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  z-index: 1;
  @include smoothness;
  .footer-logo {
    width: auto;
    height: 50px;
    margin-left: -58px;
    fill: $secondary;
    &:hover {
      fill: $white;
    }
  }
  a {
    color: $secondary;
    @include smoothness;
    &:hover {
      color: $white;
    }
  }
} 

.footer-section {
  margin-left: 75px;
  padding-inline-start: 0;
}

.footer-section-main {
  padding: 2em;
  display: flex;
  flex-direction: column;
  color: $primary-light;
}

.footer-label {
  margin-top: 75px;
  font-weight: 700;
  margin-bottom: 20px;
  color: $primary-light;
}

.footer-top {
  height: 200px;
  display: grid;
  padding: 0 40px;
  grid-template-columns: repeat(4, auto);
  margin-bottom: 100px;
}

.footer-social-links a:last-child svg {
  margin: 0;
}

.footer-bottom {
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: $black;
  width: 100%;
  p{
    margin: 0;
    color: $primary;
    &:nth-child(1) {
      position: absolute;
      left: 20px;
      color: $primary;
    }
    &:nth-child(2) {
      position: absolute;
      left: calc(50% - 114px)
    }
    a {
      color: $primary-light;
      @include smoothness;
      &:hover {
        color: #FF00FF !important;
      }
    }
  }
}

@media only screen and (max-width: 840px) {
  .footer-section-main {
    grid-column: 1;
  }
  .footer-top{
    height: 400px;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
  .footer-bottom {
    p:nth-child(2){
      left: auto;
      right: 20px;
    }
  }
  .footer-section {
    margin-left: 0;
    li {
      margin-bottom: 20px;
    }
  }
  .footer-label {
    margin-top: 0;
  }
}

@media only screen and (max-width: 540px) {
  .footer-label {
    margin-top: 20px;
  }
  .footer-bottom {
    display: flex;
    height: auto;
    flex-direction: column-reverse;
    p {
      position: relative !important;
      flex: 1;
      left: auto !important;
      right: auto !important;
    }
  }
  ol {
    padding-inline-start: 0;
  }
}