@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.header {
  color: white;
  position: fixed;
  top: 0;
  z-index: 14;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include smoothness;
  &.header-shadow {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.05);
    background: $primary;
    .header-logo {
      opacity: 1;
      width: 200px;
    }
  }
  button {
    border-radius: 0;
    margin-top: 0;
  }
  a {
    color: $white;
    &:hover {
      color: $pop;
    }
  }
  &.sticky {
    border-bottom: 1px solid $pop;
  }
}

.header-logo {
  opacity: .5;
  width: 150px;
  fill: $primary-dark;
  margin-left: 20px;
  transition: .4s cubic-bezier(.19,1,.22,1);
  height: 60px;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.dropdown {
  z-index: -1;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  height: 0px;
  width: auto;
  line-height: 1.4em;
  top: 40px;
  background: $primary;
  opacity: 0;
  pointer-events: none;
  .dropdown-item {
    padding-left: 10px;
    a {
      padding: 20px 0;
    }
  }
  @include cardShadow;
  @include smoothness;
  &.dropdown-active {
    padding-top: 16px;
    padding-bottom: 20px;
    opacity: 1;
    pointer-events: all;
    box-shadow: 1px solid black;
  }
}

.about-dropdown {
  right: 40px;
  &.dropdown-active {
    height: 114px;
  }
}

.properties-dropdown {
  right: 0px;
  width: 400px;
  &.dropdown-active {
    height: auto;
  }
}

.desktop-links {
  display: flex;
  align-items: center;
  margin-right: 40px;
  a {
    font-size: 1em; 
    padding: 20px 10px 20px 10px;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
      color: $pop;
    }
  }
}

.hamburger {
  height: 60px;
  cursor: pointer;
  display: none;
  &svg {
    width: 200px;
    height: 150px;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 40px;
    stroke-linecap: round;
    stroke: $white;
    stroke-dashoffset: 0px;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }
  
  &.cross path#top,
  &.cross path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
  }
  &.cross path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
  &:hover{
    path {
      stroke: $pop;
    }
  }
}

.mobile-nav {
  background: $primary;
  height: auto;
  width: 100%;
  align-items: center;
  flex-direction: column;
  display: none;
  position: fixed;
  top: 80px;
  z-index: 6;
  border-bottom: solid 1px rgba(0,0,0,.1);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.05);
  a {
    color: $white;
    padding: 20px;
    border-bottom: 1px solid $primary-dark;
    width: 100%;
    text-align: center;
    &:nth-child(2) {
      border-bottom: 1px solid #00202EAA;
    }
    &:nth-child(3) {
      border-bottom: none;
      color: $secondary;
    }
    &:nth-child(4) {
      border-bottom: none;
      color: $secondary;
    }
    &:nth-child(5) {
      color: $secondary;
      padding-bottom: 20px;
    }
    &:hover {
      color: $pop;
    }
  }
  .mobile-nav-sublink {
    padding: 10px;
  }
  a.btb-btn {
    display: block;
    padding: 0;
    width: 100%;
    button {
      margin: 0 20px 20px;
      width: calc(100% - 40px);
    }
  }
}

@media only screen and (max-width: 840px) {
  .mobile-nav.isActive {
    display: flex;
  }
  .desktop-links {
    display: none;
  }
  .hamburger{
    display: inline-block;
  }

  .btb-btn {
    display: none;
  }
}