 /*
 * App Style
 *
 * The base style for the application.
 *
 */
 @charset "utf-8/index";

 @import '_base.scss';
 @import '_typography.scss';
 @import '_variables.scss';
 @import '_mixins.scss';


@keyframes fontfix {
  from { opacity: 1; }
  to { opacity: 1; }
}

@-webkit-keyframes fontfix {
  from { opacity: 1; }
  to { opacity: 1; }
}

//selection-based styling
::selection {
  background: $secondary; /* WebKit/Blink Browsers */
}

html {
  --top-spacing: 100px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  transition: .4s cubic-bezier(.19,1,.22,1);
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background: $pop;
  height: 60px;
  font: inherit;
  cursor: inherit;
  padding: 0 40px;
  border: none;
  color: white;
  transition: .3s ease-in-out;
  border-radius: 8px;
  &:hover {
    background: $secondary;
    transform: translateY(-1px) scale(1.05);
  }
  &:active {
    background: $secondary;
    transform: none;
  }
}

.page {
  padding-top: 60px;}

.colored-section {
  color: white;
}

.page-title {
  margin-top: 50px;
  text-align: left;
  font-weight: 700;
}

.page-content {
  max-width: 800px;
  margin: 0 auto;
}

.scrim {
  background: $primary;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .92;
  mix-blend-mode: multiply;
  z-index: 2;
  @include slowfade;
}

.wiggle {
  position: absolute;
  width: 100%;
  bottom: -2px;
  z-index: 3;
  pointer-events: none;
}

.page-hero {
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  &:hover {
    .scrim {
      opacity: 0;
    }
  }
  >img {
    width: 100%;
    position: absolute;
    bottom: -2px;
  }
}

@media only screen and (max-width: 840px) {
  .page-content {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 540px) {
  h2 {
    font-size: calc(10px + 4vw);
  }

  h3 {
    font-size: calc(4px + 4vw);
  }

  .about-content h2 {
    font-size: calc(10px + 4vw);
  }
  .about-content h3 {
    font-size: calc(4px + 4vw);
  }
}