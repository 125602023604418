/*
 * Typography
 *
 * Website-wide text styling
 *
 */

@import 'variables.scss';
//nunito-sans
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

* {
	font-size: inherit;
	font-weight: inherit;
  color: inherit;
  vertical-align: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

a {
	color: inherit;
	text-decoration: none;
}

strong {
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}

h1,  h2,  h3,  h4,  h5,  h6 {
	margin: 0px;
}

p + p {
	margin-top: .75em;
}

li + li {
  margin-top: .5em;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-size: 2.827rem;
  font-weight: 900;
  line-height: 1.2em;
  letter-spacing: .05em;
  text-transform: uppercase;
}

h2 {
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 10px;
  letter-spacing: .05em;
  text-transform: uppercase;
}

h3 {
  font-family: 'Lato', sans-serif;
  font-size: 1.414rem;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: .02em;
}

h4 {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: .25em;
  text-transform: uppercase;
}

p {
  font-size: 1rem;
  font-weight: 300;
  max-width: 520px;
}

@media only screen and (max-width: 540px) {
  h1 {
    font-size: 10vw;
  }
}