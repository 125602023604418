/*
 * VARIABLES
 *
 * Global sizes and colors that can be used in every component
 *
 */

$bobby: "bobby", "times new roman", serif;
$source-sans: "Source Sans Pro", "system-ui",sans-serif;
$korolev: "korolev", sans-serif;
$montserrat: "montserrat", sans-serif;
$nexa: "nexa", sans-serif;


/* ============================================================ *\
|* Colors
\* ============================================================ */

$white:           #F5F1E6;
$pop:             #286EB4;
$secondary:       #0E97AF;
$primary-light:   #13709e;
$primary:         #003B59;
$primary-dark:    #00202E;
$black:           #00141D;
$scrim:           #0A142D;